import React, { useState, useEffect } from "react";
import cn from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

import Divider from "components/Divider";
import ColorPalette from "styles/ColorPalette";

import { MainScrollID } from "components/Home";

type Props = { label: string };

const useStyles = makeStyles((theme: Theme) => ({
  PageHeader: { opacity: 0.85 },
  title: {
    marginBottom: "3rem",
    textAlign: "center",
    ...ColorPalette.goldFont,
  },
}));

const PageHeader = (props: Props) => {
  const { label } = props;
  const classes = useStyles();

  const DEFAULT_ANIMATE_PROPS = {
    animateIn: "fadeIn",
    animateOnce: true,
    scrollableParentSelector: `#${MainScrollID}`,
  };

  return (
    <div className={cn(classes.PageHeader)}>
      <ScrollAnimation {...DEFAULT_ANIMATE_PROPS}>
        <Typography className={classes.title} variant="h1">
          {label}
        </Typography>
      </ScrollAnimation>
      <Divider />
    </div>
  );
};

export default PageHeader;
