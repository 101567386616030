import firebase from "firebase";
import "firebase/firestore";
import { sha256 } from "js-sha256";

import Message, { MessageData } from "models/Message";
import { sign } from "utils/security";

const config = {
  apiKey: "AIzaSyDGmaSvGJirJgbXBJp47_ab3ZhUbBI1-Eo",
  authDomain: "web-wedding-card.firebaseapp.com",
  databaseURL: "https://web-wedding-card.firebaseio.com",
  projectId: "web-wedding-card",
  storageBucket: "web-wedding-card.appspot.com",
  messagingSenderId: "1011043028321",
  appId: "1:1011043028321:web:bd0a55356533124bb5c920",
  measurementId: "G-E1P6J36MZE",
};

try {
  firebase.initializeApp(config);
  firebase.auth().signInAnonymously();
} catch (err) {
  console.error(err);
}

const db = firebase.firestore();
db.enablePersistence().catch(function(err) {
  if (err.code == "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code == "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

const signupsRef = db.collection("signups");

export async function addMessage(
  data: MessageData
): Promise<
  firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
> {
  data.password = sha256(data.password);
  data.created = Date.now();
  const docRef = await signupsRef.add(data);
  const doc = await docRef.get();
  return doc;
}

export async function updateMessage(
  message: Message,
  partialData: MessageData
) {
  const doc = message.doc;
  if (doc) {
    await doc.ref.update(partialData);
  } else {
    console.error("The message has not been posted yet!");
  }
}

export async function fetchMessages() {
  const querySnapshot = await signupsRef.get();
  const messages = [];
  querySnapshot.forEach(function(doc) {
    const newMsg = new Message(doc);
    if (!newMsg.isDeleted()) {
      messages.push(newMsg);
    }
  });
  return messages;
}

export default db;
