import React, { useRef, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme, createMuiTheme, MuiThemeProvider } from "@material-ui/core";

import Home from "components/Home";
import ColorPalette from "styles/ColorPalette";

type Props = {};

const WeddingTheme = createMuiTheme({
  typography: {
    fontFamily: "nanum-sehwa",
    h1: {
      fontFamily: "nanum-joonhaksaeng",
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.25rem",
    },
  },
  palette: {
    primary: ColorPalette.gold,
    type: "dark",
    text: {
      primary: ColorPalette.defaultText,
    },
    tonalOffset: 0.2,
  },
  overrides: {
    MuiOutlinedInput: {
      root: { backgroundColor: ColorPalette.defaultBackground + "dd" },
      multiline: {
        alignItems: "flex-start",
        minHeight: 180,
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "nanum-joonhaksaeng",
        fontSize: "1em",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "nanum-joonhaksaeng",
        fontSize: "0.9em",
      },
    },
    MuiButton: {
      label: {
        alignItems: "center",
        display: "inline-flex",
        fontSize: "1.3rem",
      },
      endIcon: {
        height: 24,
        marginLeft: 8,
      },
      iconSizeMedium: {
        height: 24,
        marginLeft: 8,
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  Root: {
    alignItems: "center",
    backgroundColor: "#000c45",
    color: ColorPalette.defaultText,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",

    "& a": {
      color: ColorPalette.defaultText,
    },
  },
}));

const Root = (props: Props) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={WeddingTheme}>
      <div className={cn(classes.Root, "scroll-root")}>
        <Home />
      </div>
    </MuiThemeProvider>
  );
};

export default Root;
