import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { ReactComponent as DividerImg } from "images/divider.svg";

type Props = { classNames?: string; [key: string]: any };

const useStyles = makeStyles((theme: Theme) => ({
  Divider: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "3rem",
    width: "100%",
  },
  line: {
    maxWidth: 300,
    width: "50%",
  },
}));

const Divider = (props: Props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={cn(classes.Divider)} {...rest}>
      <DividerImg className={classes.line} />
    </div>
  );
};

export default Divider;
