import React, { useState, useEffect } from "react";
import cn from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import ColorPalette from "styles/ColorPalette";
import ParallaxComponent from "components/ParallaxComponent";

import { ReactComponent as BottomImage } from "images/pattern1_bottom.svg";
import BackgroundTexture from "images/bg-texture.png";
import HeartLeft1 from "images/heart-left1.png";
import HeartLeft2 from "images/heart-left2.png";
import HeartLeft3 from "images/heart-left3.png";
import HeartRight1 from "images/heart-right1.png";
import HeartRight2 from "images/heart-right2.png";
import HeartRight3 from "images/heart-right3.png";

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  MainPage: {
    alignItems: "center",
    backgroundColor: ColorPalette.defaultBackground,
    backgroundImage: `url(${BackgroundTexture})`,
    color: ColorPalette.defaultText,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 700,
    position: "relative",
    width: "100%",
    zIndex: 2,
  },
  splashWrapper: {
    opacity: 0.85,
  },
  firstLine: {
    fontSize: "2.5em",
    ...ColorPalette.goldFont,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  secondLine: {
    fontSize: "3.5em",
    ...ColorPalette.goldFont,
    paddingLeft: "3rem",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  splashKorean: {},
  nameText: { fontSize: "3em", marginTop: "2rem" },
  splashEnglish: { fontSize: "1.5em", marginTop: "2rem" },
  bottomDeco: {
    marginTop: "1em",
    "& .cls-1, & .cls-2": {
      fill: `url("#GoldGradient") #DF9F28`,
    },
  },
  parallax1Text: {
    alignItems: "center",
    display: "flex",
    fontSize: "1.5em",
    height: 150,
    justifyContent: "center",
    textAlign: "center",
    padding: "2rem",
    marginTop: "3rem",
    width: "60%",
    zIndex: 2,
    ...ColorPalette.borderGoldGradient,
  },
  heartLeft: {
    position: "absolute",
    left: "50%",
    bottom: 0,
    transform: "translate(-235px, 110px)",
    zIndex: 0,
  },
  heartLeftImg: {
    width: 300,
  },
  heartRight: {
    position: "absolute",
    right: "50%",
    top: "50%",
    transform: "translate(340px, -365px)",
    zIndex: 0,
  },
  heartRightImg: {
    width: 300,
  },
  right: { display: "flex" },
  rParents: {},
  rName: {},
  left: { display: "flex" },
  lParents: {},
  lName: {},
  adj: { fontSize: "0.8em" },
  nameBox: {
    display: "flex",
  },
}));

const MainPage = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.MainPage)}>
      <ParallaxComponent
        className={classes.heartLeft}
        sensitivityY={-3}
        offsetY={-50}>
        <img src={HeartLeft1} className={classes.heartLeftImg} />
      </ParallaxComponent>
      <ParallaxComponent
        className={classes.heartLeft}
        sensitivityY={1}
        offsetY={5}>
        <img src={HeartLeft2} className={classes.heartLeftImg} />
      </ParallaxComponent>
      <ParallaxComponent
        className={classes.heartLeft}
        sensitivityY={5}
        offsetY={100}>
        <img src={HeartLeft3} className={classes.heartLeftImg} />
      </ParallaxComponent>
      <ParallaxComponent
        className={classes.heartRight}
        sensitivityY={-2}
        offsetY={-75}>
        <img src={HeartRight2} className={classes.heartRightImg} />
      </ParallaxComponent>
      <ParallaxComponent
        className={classes.heartRight}
        sensitivityY={-6}
        offsetY={-250}>
        <img src={HeartRight3} className={classes.heartRightImg} />
      </ParallaxComponent>
      <div className={classes.splashWrapper}>
        <div className={classes.splashKorean}>
          <div className={classes.firstLine}>안녕하세요,</div>
          <div className={classes.secondLine}>우리 결혼합니다!</div>
        </div>
        <BottomImage className={classes.bottomDeco} />
      </div>
      <div className={classes.splashEnglish}>We are getting married!</div>
      <div className={cn(classes.nameText)}>정나은 x 김현규</div>
      <div className={classes.splashEnglish}>
        2020. 5. 30. 토요일 / 혼례미사 오후 1시
      </div>
    </div>
  );
};

export default MainPage;
