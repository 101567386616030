import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import ColorPalette from "styles/ColorPalette";
import { ReactComponent as Girl } from "images/Girl.svg";
import { ReactComponent as Boy } from "images/Boy.svg";
import { ReactComponent as HeartCornerDeco } from "images/HeartCornerDeco.svg";
import HeartWaxImg from "images/HeartWax.png";

type Props = { onOpenCover?: Function };

const useStyles = makeStyles((theme: Theme) => ({
  CardCover: {},
  leftPanel: {
    alignItems: "center",
    backgroundColor: "#1b2972",
    display: "flex",
    position: "fixed",
    left: 0,
    top: 0,
    transformOrigin: "left",
    transformStyle: "preserve-3d",
    transition: "transform 1s ease-out",
    height: "100%",
    width: "50%",
    zIndex: 55,
    "&$open": {
      transform: "rotateY(-90deg)",
    },
    "&::before": {
      background: ColorPalette.goldGradient,
      content: "' '",
      height: "100%",
      right: 0,
      position: "absolute",
      top: 0,
      width: 5,
      transform: "rotateY(-90deg)",
      transformOrigin: "100% 50%",
    },
  },
  rightPanel: {
    alignItems: "center",
    background: ColorPalette.goldGradient,
    display: "flex",
    position: "fixed",
    right: 0,
    top: 0,
    transformOrigin: "right",
    transformStyle: "preserve-3d",
    transition: "transform 1.5s ease-out",
    transitionDelay: "0.5s",
    height: "100%",
    width: "50%",
    zIndex: 50,
    "&$open": {
      transform: "rotateY(90deg)",
    },
    "&::before": {
      background: ColorPalette.goldGradient,
      content: "' '",
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: 5,
      transform: "rotateY(-90deg)",
      transformOrigin: "100% 50%",
    },
  },
  open: {},
  girl: {
    animation: "$girl 2s ease-out",
    width: 136,
    position: "absolute",
    right: -3,
    marginTop: 4,
  },
  boy: {
    animation: "$boy 2.5s ease-out",
    width: 140,
    position: "absolute",
    left: -17,
    "& .boy-cls-silhouette": {
      fill: "#1b2972",
    },
  },
  leftCornerDeco: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
    maxWidth: 250,
    width: "75%",
    "& g.heart-deco": {
      fill: "url(#GoldGradient)",
    },
  },
  rightCornerDeco: {
    position: "absolute",
    bottom: "1rem",
    right: "1rem",
    maxWidth: 250,
    width: "75%",
    transform: "rotateZ(180deg)",
    "& g.heart-deco": { fill: "#1b2972" },
  },
  waxStamp: {
    transform: "translateY(30px)",
    position: "absolute",
    right: -40,
    width: 80,
  },
  "@keyframes girl": {
    from: { opacity: 0, transform: "translateX(-10px)" },
    to: { opacity: 1, transform: "translateX(0)" },
  },
  "@keyframes boy": {
    "0%": { opacity: 0 },
    "10%": { opacity: 0, transform: "translateX(20px)" },
    "100%": { opacity: 1, transform: "translateX(0)" },
  },
}));

const CardCover = (props: Props) => {
  const { onOpenCover } = props;
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();

  function handleClickPanel() {
    setOpen(true);
    onOpenCover && onOpenCover();
  }

  return (
    <>
      <div
        onClick={handleClickPanel}
        className={cn(classes.leftPanel, { [classes.open]: isOpen })}>
        <HeartCornerDeco className={classes.leftCornerDeco} />
        <Girl className={cn(classes.girl)} />
        <img src={HeartWaxImg} className={classes.waxStamp} />
      </div>
      <div
        onClick={handleClickPanel}
        className={cn(classes.rightPanel, { [classes.open]: isOpen })}>
        <HeartCornerDeco className={classes.rightCornerDeco} />
        <Boy className={cn(classes.boy)} />
      </div>
    </>
  );
};

export default CardCover;
