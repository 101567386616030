import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";

import CardCover from "components/CardCover";
import PageScrollWrapper from "components/PageScrollWrapper";
import MainPage from "components/Pages/MainPage";
import LocationPage from "components/Pages/LocationPage";
import IntroPage from "components/Pages/IntroPage";
import GuestBookPage from "components/Pages/GuestBookPage";

import ColorPalette from "styles/ColorPalette";

import Parallax1Image from "images/parallax1.png";
import GoldTexture from "images/gold-gradient.png";

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  Home: {
    alignItems: "center",
    backgroundColor: "#192564",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    maxWidth: 1000,
    overflowY: "hidden",
    perspective: 500,
    transformStyle: "preserve-3d",
    width: "100%",
  },
  parallax1: {
    alignItems: "center",
    backgroundImage: `url(${Parallax1Image})`,
    backgroundAttachment: "fixed",
    backgroundPosition: "top",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    height: 300,
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
  parallax1Text: {
    alignItems: "center",
    border: "3px solid " + ColorPalette.defaultText,
    borderImageSource: `linear-gradient(45deg, #8f6B29, #FDE08D, #DF9F28)`,
    borderImageSlice: 1,
    display: "flex",
    fontSize: "1.5em",
    height: "35%",
    justifyContent: "center",
    textAlign: "center",
    padding: "2rem",
    width: "60%",
  },
}));

export const MainScrollID = "main-scroll";

const Home = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={cn(classes.Home)}>
      <CardCover />
      <PageScrollWrapper id={MainScrollID}>
        <MainPage />
        <IntroPage />
        <LocationPage />
        <GuestBookPage />
      </PageScrollWrapper>
      <svg>
        <defs>
          <linearGradient id="GoldGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#8f6B29" />
            <stop offset="50%" stopColor="#FDE08D" />
            <stop offset="100%" stopColor="#DF9F28" />
          </linearGradient>
          <pattern
            id="GoldTexture"
            patternUnits="userSpaceOnUse"
            height="200"
            width="300">
            <image
              xlinkHref={GoldTexture}
              x="0"
              y="0"
              height="200"
              width="300"
            />
          </pattern>
        </defs>
      </svg>
    </div>
  );
};

export default Home;
