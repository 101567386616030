import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme, Typography, Icon } from "@material-ui/core";

import PageHeader from "components/PageHeader";

import ColorPalette from "styles/ColorPalette";
import ParallaxComponent from "components/ParallaxComponent";
import BackgroundTexture from "images/bg-texture.png";
import BranchLeft from "images/branch-left.png";
import BranchRight from "images/branch-right.png";

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  LocationPage: {
    backgroundColor: ColorPalette.defaultBackgroundAlt,
    padding: "3rem 4%",
    position: "relative",
    zIndex: 2,
  },
  map: {
    position: "relative",
    "$LocationPage &": {
      border: 0,
      boxSizing: "border-box",
      padding: "0",
      margin: "0 auto",
      maxWidth: 500,
      width: "100%",
    },
  },
  mapBlocker: {
    position: "absolute",
    width: "calc(100% - 49px)",
    height: "calc(100% - 30px)",
    zIndex: 2,
  },
  locationText: {
    fontSize: "1.5em",
    marginBottom: "2rem",
    textAlign: "center",
  },
  locationLink: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: "1.4em",
    justifyContent: "center",
    "& .material-icons": {
      fontSize: "0.5em",
    },
  },
  branchLeft: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-250px, -250px)",
    zIndex: 1,
  },
  branchLeftImg: {
    width: 200,
  },
  branchRight: {
    position: "absolute",
    right: "50%",
    top: "50%",
    transform: "translate(295px, -365px)",
    zIndex: 40,
  },
  branchRightImg: {
    width: 200,
  },
  mapLink: {
    fontSize: "0.5em",
  },
  "@media (min-width: 450px)": {
    branchLeft: {
      left: "-10%",
      transform: "translate(0, -270px)",
    },
    branchLeftImg: {
      width: 400,
    },
    branchRight: {
      right: "-10%",
      transform: "translate(0, -365px)",
    },
    branchRightImg: {
      width: 300,
    },
  },
  "@media (min-width: 900px)": {
    branchLeftImg: {
      width: 500,
    },
    branchLeft: {
      left: "-10%",
      transform: "translate(0, -270px)",
    },
    branchRight: {
      right: "-10%",
      transform: "translate(0, -365px)",
    },
    branchRightImg: {
      width: 400,
    },
  },
}));

declare global {
  interface Window {
    daum: any;
  }
}
interface KakaoMap {
  setDraggable: Function;
}

const renderMap = () => {
  if (window.daum) {
    const map: KakaoMap = new window.daum.roughmap.Lander({
      timestamp: "1583048108996",
      key: "xa8n",
      mapWidth: "100%",
      mapHeight: "300",
      draggable: false,
    }).render();
  }
};

const LocationPage = (props: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (!window.daum) {
      const script = document.createElement("script");
      script.src =
        "https://ssl.daumcdn.net/dmaps/map_js_init/roughmapLoader.js";
      script.onload = renderMap;
      script.async = true;

      window.document.appendChild(script);
    } else {
      renderMap();
    }
  }, []);

  return (
    <div className={cn(classes.LocationPage)}>
      <PageHeader label="LOCATION" />
      <div className={cn(classes.locationText)}>
        <a
          className={cn(classes.locationLink)}
          target="_blank"
          href="http://naver.me/xl3HAZq5">
          프란치스코회성모기사회
          <br />
          <div className={classes.mapLink}>
            (지도열기)
            <Icon className="right-icon">map</Icon>
          </div>
        </a>
        <br />
        경기 양평군 서종면 중미산로 136
      </div>
      <div
        id="daumRoughmapContainer1583048108996"
        className={cn(
          "root_daum_roughmap",
          "root_daum_roughmap_landing",
          classes.map
        )}>
        <div className={classes.mapBlocker} />
      </div>
      <ParallaxComponent
        className={classes.branchLeft}
        sensitivityY={-2}
        offsetY={-150}>
        <img src={BranchLeft} className={classes.branchLeftImg} />
      </ParallaxComponent>
      <ParallaxComponent
        className={classes.branchRight}
        sensitivityY={6}
        offsetY={240}>
        <img src={BranchRight} className={classes.branchRightImg} />
      </ParallaxComponent>
    </div>
  );
};

export default LocationPage;
