import React, { useState, useEffect } from "react";
import cn from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import { MainScrollID } from "components/Home";

import ColorPalette from "styles/ColorPalette";
import BackgroundTexture from "images/bg-texture.png";

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  IntroPage: {
    alignItems: "center",
    backgroundColor: ColorPalette.defaultBackground,
    backgroundImage: `url(${BackgroundTexture})`,
    color: ColorPalette.defaultText,
    display: "flex",
    flexDirection: "column",
    height: 700,
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  frame: {
    width: "95%",
  },
  contentWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  mainText: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    fontSize: "1.5em",
    flexDirection: "column",
    height: 550,
    justifyContent: "center",
    textAlign: "center",
    padding: "2rem",
    marginTop: "3rem",
    maxWidth: 450,
    minWidth: 330,
    width: "60%",
    zIndex: 2,
    ...ColorPalette.borderGoldGradient,
  },
  right: { display: "flex", marginTop: "1rem" },
  parents: {},
  name: { alignItems: "center", display: "flex", fontSize: "1.5em" },
  left: { display: "flex" },
  adj: { fontSize: "0.5em", margin: "0 8px" },
  nameBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
  },
  paragraph1: {
    margin: "0 0 1em",
    textAlign: "left",
  },
  paragraph2: {
    margin: "1em 0",
    textAlign: "left",
  },
}));

const IntroPage = (props: Props) => {
  const classes = useStyles();

  const DEFAULT_ANIMATE_PROPS = {
    animateIn: "fadeIn",
    animateOnce: true,
    scrollableParentSelector: `#${MainScrollID}`,
  };

  return (
    <div className={cn(classes.IntroPage)}>
      <div className={cn(classes.contentWrapper)}>
        <div className={cn(classes.mainText)}>
          <div className={classes.paragraph1}>
            <ScrollAnimation {...DEFAULT_ANIMATE_PROPS}>
              Ever thine,
            </ScrollAnimation>
            <br />
            <ScrollAnimation {...DEFAULT_ANIMATE_PROPS}>
              ever mine,
            </ScrollAnimation>
            <br />
            <ScrollAnimation {...DEFAULT_ANIMATE_PROPS}>
              ever ours.
            </ScrollAnimation>
          </div>

          <div className={classes.paragraph2}>
            <ScrollAnimation {...DEFAULT_ANIMATE_PROPS}>
              저희 두 사람 영원한 사랑을 맹세하는 자리에 함께해주세요.
            </ScrollAnimation>
          </div>

          <div className={classes.nameBox}>
            <ScrollAnimation
              className={classes.left}
              {...DEFAULT_ANIMATE_PROPS}>
              <div className={classes.parents}>
                정해원
                <br />
                장귀옥
              </div>

              <div className={classes.name}>
                <span className={classes.adj}>의 딸</span> 정나은
              </div>
            </ScrollAnimation>

            <ScrollAnimation
              className={classes.right}
              {...DEFAULT_ANIMATE_PROPS}>
              <div className={classes.parents}>
                김영성
                <br />
                김영경
              </div>

              <div className={classes.name}>
                <span className={classes.adj}>의 아들</span> 김현규
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroPage;
