import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import Message from "models/Message";
import ColorPalette from "styles/ColorPalette";

type Props = { messages: Array<Message> };

const useStyles = makeStyles((theme: Theme) => ({
  MessageCardList: {
    height: 300,
    overflowY: "scroll",
    ...ColorPalette.borderGoldGradient,
  },
}));

const MessageCardList = (props: Props) => {
  const classes = useStyles();
  const { messages } = props;

  messages.sort((aa, bb) => {
    return aa.getData().created - bb.getData().created;
  });

  return (
    <div className={cn(classes.MessageCardList)}>
      {messages.map(msg => msg.renderCard())}
    </div>
  );
};

export default MessageCardList;
