import React from "react";
import firebase from "firebase";

import MessageCard from "components/MessageCard";
import { addMessage, updateMessage } from "utils/database";

export interface MessageData {
  id?: string;
  deviceFingerprint?: string;
  message?: string;
  name?: string;
  number?: number;
  created?: number;
  deleted?: number;
  password?: string;
}

class Message {
  doc?: firebase.firestore.QueryDocumentSnapshot<
    firebase.firestore.DocumentData
  >;
  _data: MessageData = {};

  constructor(
    doc?: firebase.firestore.QueryDocumentSnapshot<
      firebase.firestore.DocumentData
    >
  ) {
    if (doc) {
      this.doc = doc;
      this._data = {
        id: doc.id,
        ...doc.data(),
      };
    }
  }

  getData = () => {
    return this._data;
  };

  getDocRef = () => {
    return this.doc ? this.doc.ref : null;
  };

  post = async (partialData: MessageData = {}) => {
    try {
      const doc = await addMessage({ ...this._data, ...partialData });

      this.doc = doc;
    } catch (err) {
      console.error("Could not post the message: ", err);
    }
  };

  update = async (partialData: MessageData = {}) => {
    if (this.doc) {
      try {
        await updateMessage(this, partialData);
      } catch (err) {
        console.error("Could not update the message: ", err);
      }
    } else {
      console.error("This message is not available on the server");
    }
  };

  delete = async () => {
    if (this.doc) {
      try {
        await updateMessage(this, { deleted: 1 });
      } catch (err) {
        console.error("Could not update the message: ", err);
      }
    } else {
      console.error("This message is not available on the server");
    }
  };

  isDeleted = () => {
    return this._data.deleted === 1;
  };

  renderCard = () => {
    const { message, name, id } = this._data;
    if (!message) {
      return null;
    }
    return <MessageCard data={this._data} model={this} key={id} />;
  };
}

export default Message;
