import React, { useState, useEffect, useRef, ReactNode } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

type Props = {
  className?: string;
  sensitivityX?: number;
  sensitivityY?: number;
  offsetX?: number;
  offsetY?: number;
  strength?: number;
  children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  ParallaxComponent: { position: "relative" },
  innderWrapper: {
    height: "100%",
    transition: "transform 50ms linear",
    width: "100%",
  },
}));

let scrollRoot = null;

const components = {};

setInterval(() => {
  for (const kv in components) {
    const fn = components[kv];
    fn && fn();
  }
}, 15);

const ParallaxComponent = (props: Props) => {
  const {
    children,
    className,
    sensitivityX = 0,
    sensitivityY = 1,
    offsetX = 0,
    offsetY = 0,
    strength = 50,
  } = props;
  const [uid, setUid] = useState(Math.floor(Math.random() * 10000 + 10));
  const [xOffset, setXOffset] = useState(offsetX);
  const [yOffset, setYOffset] = useState(offsetY);
  const classes = useStyles();
  const mainRef = useRef(null);

  const handleRootWheel = () => (ev?: Event) => {
    if (mainRef && mainRef.current) {
      const el = mainRef.current;
      const rect = el.getBoundingClientRect();

      const top = rect.top;
      const bottom = rect.top + rect.height;
      const mid = (top + bottom) / 2;
      const screenHeight = window.innerHeight;
      if (top < screenHeight && bottom > 0) {
        setYOffset(
          ((mid - screenHeight) / screenHeight) * strength * sensitivityY +
            offsetY
        );
      }
    }
  };

  useEffect(() => {
    if (!components[uid]) {
      components[uid] = handleRootWheel();
    }
    setTimeout(() => {
      handleRootWheel()();
    }, 200);
    return () => {
      delete components[uid];
    };
  });
  const innerStyle = {
    transform: `translate(${xOffset}px, ${yOffset}px)`,
  };

  return (
    <div className={cn(classes.ParallaxComponent, className)} ref={mainRef}>
      <div className={classes.innderWrapper} style={innerStyle}>
        {children}
      </div>
    </div>
  );
};

export default ParallaxComponent;
