import React, { useEffect, useState, ReactNode } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

type Props = { children?: ReactNode; id?: string };

export const ScrollWrapperContext = React.createContext(null);

const useStyles = makeStyles((theme: Theme) => ({
  PageScrollWrapper: {
    height: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
    position: "absolute",
    scrollBehavior: "smooth",
    width: "100%",
  },
}));

const PageScrollWrapper = (props: Props) => {
  const classes = useStyles();
  const { children } = props;

  const extraProps: Props = {};

  if (props.id) {
    extraProps.id = props.id;
  }

  return (
    <div className={cn(classes.PageScrollWrapper)} {...extraProps}>
      {children}
    </div>
  );
};

export default PageScrollWrapper;
