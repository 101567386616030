// 71 => opacify 28%
// 51 => opacify 20%
type Color = {
  name: string;
  "50": string;
  "100": string;
  "200": string;
  "300": string;
  "400": string;
  "500": string;
  "600": string;
  "700": string;
  "800": string;
  "900": string;
  A100?: string;
  A200?: string;
  A400?: string;
  A700?: string;
  contrastText?: string;
  boxShadow?: string;
  boxShadowSmall?: string;
  gradient?: string;
};

export const defaultText = "rgba(255,213,79,0.87)";
export const defaultTextLight = "rgba(0, 0, 0, 0.54)";
export const defaultTextInverted = "rgba(255, 255, 255, 0.87)";

export const defaultBackground = "#192564";
export const defaultBackgroundAlt = "#0b3f66";

export const borderGoldGradient = {
  border: "3px solid " + defaultText,
  borderImageSource: `linear-gradient(45deg, #8f6B29, #FDE08D, #DF9F28)`,
  borderImageSlice: 1,
};

export const gold: Color = {
  name: "cyan",
  "50": "#fffde7",
  "100": "#fff9c4",
  "200": "#fff59d",
  "300": "#ffff81",
  "400": "#fcec82",
  "500": "#ffd54f",
  "600": "#f2cd00",
  "700": "#c8a415",
  "800": "#b79500",
  "900": "#a17800",
  A100: "#ffdc00",
  A200: "#ffff00",
  A400: "#ffea00",
  A700: "#ffd600",
  contrastText: "rgba(0,0,0,0.87",
};

export const cyan: Color = {
  name: "cyan",
  "50": "#e0f7fa",
  "100": "#b2ebf2",
  "200": "#80deea",
  "300": "#4dd0e1",
  "400": "#26c6da",
  "500": "#00bcd4",
  "600": "#00acc1",
  "700": "#0097a7",
  "800": "#00838f",
  "900": "#006064",
  A100: "#84ffff",
  A200: "#18ffff",
  A400: "#00e5ff",
  A700: "#00b8d4",
  contrastText: defaultTextInverted,
};
cyan.boxShadow = `0 12px 20px -10px ${cyan[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${cyan[700]}51`;
cyan.boxShadowSmall = `0 4px 8px -6px ${cyan[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${cyan[700]}51`;
cyan.gradient = `linear-gradient(60deg, ${cyan[400]}, ${cyan[600]})`;

export const teal: Color = {
  name: "teal",
  "50": "#e0f2f1",
  "100": "#b2dfdb",
  "200": "#80cbc4",
  "300": "#4db6ac",
  "400": "#26a69a",
  "500": "#009688",
  "600": "#00897b",
  "700": "#00796b",
  "800": "#00695c",
  "900": "#004d40",
  contrastText: defaultTextInverted,
};
teal.boxShadow = `0 12px 20px -10px ${teal[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${teal[700]}51`;
teal.boxShadowSmall = `0 4px 8px -6px ${teal[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${teal[700]}51`;
teal.gradient = `linear-gradient(60deg, ${teal[400]}, ${teal[600]})`;

export const pink: Color = {
  name: "pink",
  "50": "#fce4ec",
  "100": "#f8bbd0",
  "200": "#f48fb1",
  "300": "#f06292",
  "400": "#ec407a",
  "500": "#e91e63",
  "600": "#d81b60",
  "700": "#c2185b",
  "800": "#ad1457",
  "900": "#880e4f",
  A100: "#ff80ab",
  A200: "#ff4081",
  A400: "#f50057",
  A700: "#c51162",
  contrastText: defaultTextInverted,
};
pink.boxShadow = `0 12px 20px -10px ${pink[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${pink[700]}51`;
pink.boxShadowSmall = `0 4px 8px -6px ${pink[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${pink[700]}51`;
pink.gradient = `linear-gradient(60deg, ${pink[400]}, ${pink[600]})`;

export const grey: Color = {
  name: "grey",
  "50": "#fafafa",
  "100": "#f5f5f5",
  "200": "#eeeeee",
  "300": "#e0e0e0",
  "400": "#bdbdbd",
  "500": "#9e9e9e",
  "600": "#757575",
  "700": "#616161",
  "800": "#424242",
  "900": "#212121",
  contrastText: defaultTextInverted,
};
grey.boxShadow = `0 12px 20px -10px ${grey[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${grey[700]}51`;
grey.boxShadowSmall = `0 4px 8px -6px ${grey[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${grey[700]}51`;
grey.gradient = `linear-gradient(60deg, ${grey[400]}, ${grey[600]})`;

export const purple: Color = {
  name: "purple",
  "50": "#f3e5f5",
  "100": "#e1bee7",
  "200": "#ce93d8",
  "300": "#ba68c8",
  "400": "#ab47bc",
  "500": "#9c27b0",
  "600": "#8e24aa",
  "700": "#7b1fa2",
  "800": "#6a1b9a",
  "900": "#4a148c",
  A100: "#ea80fc",
  A200: "#e040fb",
  A400: "#d500f9",
  A700: "#aa00ff",
  contrastText: defaultTextInverted,
};
purple.boxShadow = `0 12px 20px -10px ${purple[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${purple[700]}51`;
purple.boxShadowSmall = `0 4px 8px -6px ${purple[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${purple[700]}51`;
purple.gradient = `linear-gradient(60deg, ${purple[400]}, ${purple[600]})`;

export const deepPurple: Color = {
  name: "deepPurple",
  "50": "#ede7f6",
  "100": "#d1c4e9",
  "200": "#b39ddb",
  "300": "#9575cd",
  "400": "#7e57c2",
  "500": "#673ab7",
  "600": "#5e35b1",
  "700": "#512da8",
  "800": "#4527a0",
  "900": "#311b92",
  A100: "#b388ff",
  A200: "#7c4dff",
  A400: "#651fff",
  A700: "#6200ea",
  contrastText: defaultTextInverted,
};
deepPurple.boxShadow = `0 12px 20px -10px ${deepPurple[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${deepPurple[700]}51`;
deepPurple.boxShadowSmall = `0 4px 8px -6px ${deepPurple[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${deepPurple[700]}51`;
deepPurple.gradient = `linear-gradient(60deg, ${deepPurple[400]}, ${deepPurple[600]})`;

export const indigo: Color = {
  name: "indigo",
  "50": "#e8eaf6",
  "100": "#c5cae9",
  "200": "#9fa8da",
  "300": "#7986cb",
  "400": "#5c6bc0",
  "500": "#3f51b5",
  "600": "#3949ab",
  "700": "#303f9f",
  "800": "#283593",
  "900": "#1a237e",
  A100: "#8c9eff",
  A200: "#536dfe",
  A400: "#3d5afe",
  A700: "#304ffe",
  contrastText: defaultTextInverted,
};
indigo.boxShadow = `0 12px 20px -10px ${indigo[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${indigo[700]}51`;
indigo.boxShadowSmall = `0 4px 8px -6px ${indigo[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${indigo[700]}51`;
indigo.gradient = `linear-gradient(60deg, ${indigo[400]}, ${indigo[600]})`;

export const blue: Color = {
  name: "blue",
  "50": "#e3f2fd",
  "100": "#bbdefb",
  "200": "#90caf9",
  "300": "#64b5f6",
  "400": "#42a5f5",
  "500": "#2196f3",
  "600": "#1e88e5",
  "700": "#1976d2",
  "800": "#1565c0",
  "900": "#0d47a1",
  A100: "#82b1ff",
  A200: "#448aff",
  A400: "#2979ff",
  A700: "#2962ff",
  contrastText: defaultTextInverted,
};
blue.boxShadow = `0 12px 20px -10px ${blue[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${blue[700]}51`;
blue.boxShadowSmall = `0 4px 8px -6px ${blue[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${blue[700]}51`;
blue.gradient = `linear-gradient(60deg, ${blue[400]}, ${blue[600]})`;

export const lightBlue: Color = {
  name: "lightBlue",
  "50": "#e1f5fe",
  "100": "#b3e5fc",
  "200": "#81d4fa",
  "300": "#4fc3f7",
  "400": "#29b6f6",
  "500": "#03a9f4",
  "600": "#039be5",
  "700": "#0288d1",
  "800": "#0277bd",
  "900": "#01579b",
  A100: "#80d8ff",
  A200: "#40c4ff",
  A400: "#00b0ff",
  A700: "#0091ea",
  contrastText: defaultTextInverted,
};
lightBlue.boxShadow = `0 12px 20px -10px ${lightBlue[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${lightBlue[700]}51`;
lightBlue.boxShadowSmall = `0 4px 8px -6px ${lightBlue[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${lightBlue[700]}51`;
lightBlue.gradient = `linear-gradient(60deg, ${lightBlue[400]}, ${lightBlue[600]})`;

export const green: Color = {
  name: "green",
  "50": "#e8f5e9",
  "100": "#c8e6c9",
  "200": "#a5d6a7",
  "300": "#81c784",
  "400": "#66bb6a",
  "500": "#4caf50",
  "600": "#43a047",
  "700": "#388e3c",
  "800": "#2e7d32",
  "900": "#1b5e20",
  A100: "#b9f6ca",
  A200: "#69f0ae",
  A400: "#00e676",
  A700: "#00c853",
  contrastText: defaultTextInverted,
};
green.boxShadow = `0 12px 20px -10px ${green[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${green[700]}51`;
green.boxShadowSmall = `0 4px 8px -6px ${green[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${green[700]}51`;
green.gradient = `linear-gradient(60deg, ${green[400]}, ${green[600]})`;

export const lightGreen: Color = {
  name: "lightGreen",
  "50": "#f1f8e9",
  "100": "#dcedc8",
  "200": "#c5e1a5",
  "300": "#aed581",
  "400": "#9ccc65",
  "500": "#8bc34a",
  "600": "#7cb342",
  "700": "#689f38",
  "800": "#558b2f",
  "900": "#33691e",
  A100: "#ccff90",
  A200: "#b2ff59",
  A400: "#76ff03",
  A700: "#64dd17",
  contrastText: defaultTextInverted,
};
lightGreen.boxShadow = `0 12px 20px -10px ${lightGreen[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${lightGreen[700]}51`;
lightGreen.boxShadowSmall = `0 4px 8px -6px ${lightGreen[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${lightGreen[700]}51`;
lightGreen.gradient = `linear-gradient(60deg, ${lightGreen[400]}, ${lightGreen[600]})`;

export const lime: Color = {
  name: "lime",
  "50": "#f9fbe7",
  "100": "#f0f4c3",
  "200": "#e6ee9c",
  "300": "#dce775",
  "400": "#d4e157",
  "500": "#cddc39",
  "600": "#c0ca33",
  "700": "#afb42b",
  "800": "#9e9d24",
  "900": "#827717",
  A100: "#f4ff81",
  A200: "#eeff41",
  A400: "#c6ff00",
  A700: "#aeea00",
};
lime.boxShadow = `0 12px 20px -10px ${lime[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${lime[700]}51`;
lime.boxShadowSmall = `0 4px 8px -6px ${lime[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${lime[700]}51`;
lime.gradient = `linear-gradient(60deg, ${lime[400]}, ${lime[600]})`;

export const yellow: Color = {
  name: "yellow",
  "50": "#fffde7",
  "100": "#fff9c4",
  "200": "#fff59d",
  "300": "#fff176",
  "400": "#ffee58",
  "500": "#ffeb3b",
  "600": "#fdd835",
  "700": "#fbc02d",
  "800": "#f9a825",
  "900": "#f57f17",
  A100: "#ffff8d",
  A200: "#ffff00",
  A400: "#ffea00",
  A700: "#ffd600",
};
yellow.boxShadow = `0 12px 20px -10px ${yellow[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${yellow[700]}51`;
yellow.boxShadowSmall = `0 4px 8px -6px ${yellow[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${yellow[700]}51`;
yellow.gradient = `linear-gradient(60deg, ${yellow[400]}, ${yellow[600]})`;

export const amber: Color = {
  name: "amber",
  "50": "#fff8e1",
  "100": "#ffecb3",
  // "100": "#ffe082",
  "200": "#ffd54f",
  "300": "#ffca28",
  "400": "#ffc107",
  "500": "#ffb300",
  "600": "#ffa000",
  "700": "#ff8f00",
  "800": "#ff6f00",
  "900": "#ff6f00",
  A100: "#ffe57f",
  A200: "#ffd740",
  A400: "#ffc400",
  A700: "#ffab00",
  contrastText: defaultTextInverted,
};
amber.boxShadow = `0 12px 20px -10px ${amber[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${amber[700]}51`;
amber.boxShadowSmall = `0 4px 8px -6px ${amber[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${amber[700]}51`;

amber.gradient = `linear-gradient(60deg, ${amber[600]}, ${amber[400]})`;

export const orange: Color = {
  name: "orange",
  "50": "#fff3e0",
  "100": "#ffe0b2",
  "200": "#ffcc80",
  "300": "#ffb74d",
  "400": "#ffa726",
  "500": "#ff9800",
  "600": "#fb8c00",
  "700": "#f57c00",
  "800": "#ef6c00",
  "900": "#e65100",
  A100: "#ffd180",
  A200: "#ffab40",
  A400: "#ff9100",
  A700: "#ff6d00",
  contrastText: defaultTextInverted,
};
orange.boxShadow = `0 12px 20px -10px ${orange[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${orange[700]}51`;
orange.boxShadowSmall = `0 4px 8px -6px ${orange[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${orange[700]}51`;
orange.gradient = `linear-gradient(60deg, ${orange[400]}, ${orange[600]})`;

export const red: Color = {
  name: "red",
  "50": "#ffebee",
  "100": "#ffcdd2",
  "200": "#ef9a9a",
  "300": "#e57373",
  "400": "#ef5350",
  "500": "#f44336",
  "600": "#e53935",
  "700": "#d32f2f",
  "800": "#c62828",
  "900": "#b71c1c",
  A100: "#ff8a80",
  A200: "#ff5252",
  A400: "#ff1744",
  A700: "#d50000",
  contrastText: defaultTextInverted,
};
red.boxShadow = `0 12px 20px -10px ${red[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${red[700]}51`;
red.boxShadowSmall = `0 4px 8px -6px ${red[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${red[700]}51`;
red.gradient = `linear-gradient(60deg, ${red[400]}, ${red[600]})`;

export const deepOrange: Color = {
  name: "deepOrange",
  "50": "#fbe9e7",
  "100": "#ffccbc",
  "200": "#ffab91",
  "300": "#ff8a65",
  "400": "#ff7043",
  "500": "#ff5722",
  "600": "#f4511e",
  "700": "#e64a19",
  "800": "#d84315",
  "900": "#bf360c",
  A100: "#ff9e80",
  A200: "#ff6e40",
  A400: "#ff3d00",
  A700: "#dd2c00",
  contrastText: defaultTextInverted,
};
deepOrange.boxShadow = `0 12px 20px -10px ${deepOrange[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${deepOrange[700]}51`;
deepOrange.boxShadowSmall = `0 4px 8px -6px ${deepOrange[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${deepOrange[700]}51`;
deepOrange.gradient = `linear-gradient(60deg, ${deepOrange[400]}, ${deepOrange[600]})`;

export const brown: Color = {
  name: "brown",
  "50": "#efebe9",
  "100": "#d7ccc8",
  "200": "#bcaaa4",
  "300": "#a1887f",
  "400": "#8d6e63",
  "500": "#795548",
  "600": "#6d4c41",
  "700": "#5d4037",
  "800": "#4e342e",
  "900": "#3e2723",
  contrastText: defaultTextInverted,
};
brown.boxShadow = `0 12px 20px -10px ${brown[700]}71, 0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${brown[700]}51`;
brown.boxShadowSmall = `0 4px 8px -6px ${brown[700]}71, 0 4px 10px 0 rgba(0, 0, 0, 0.12), 0 5px 6px -4px ${brown[700]}51`;
brown.gradient = `linear-gradient(60deg, ${brown[400]}, ${brown[600]})`;

export const goldGradient =
  "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #c5af49 62.5%, #c5af49 100%)";
export const goldFont = {
  background: "linear-gradient(#8f6B29, #FDE08D, #DF9F28)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

const colors = {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  gold,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
  goldGradient,
  goldFont,
  defaultBackground,
  defaultBackgroundAlt,
  defaultText,
  defaultTextLight,
  defaultTextInverted,
  borderGoldGradient,
};

export default colors;
