import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import { sha256 } from "js-sha256";
import {
  Theme,
  Icon,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import Message, { MessageData } from "models/Message";
import ColorPalette from "styles/ColorPalette";
type Props = {
  data: MessageData;
  model: Message;
};

const useStyles = makeStyles((theme: Theme) => ({
  MessageCard: {
    background: "transparent",
    fontSize: "1.5rem",
    maxHeight: "20rem",
    minHeight: "3em",
    overflowY: "hidden",
    transition: "0.5s ease-out",
    padding: "0.5rem 0.8rem",
    position: "relative",
    opacity: 1,
    "&:after": {
      ...ColorPalette.borderGoldGradient,
      bottom: 0,
      border: "1px solid",
      content: "' '",
      display: "block",
      left: "5%",
      position: "absolute",
      width: "90%",
    },
    "&$deleted": {
      maxHeight: 0,
      minHeight: 0,
      padding: 0,
      margin: 0,
      opacity: 0,
      "&:after": {
        display: "none",
      },
    },
  },
  deleted: {},
  deleteButton: {
    minWidth: 36,
    position: "absolute",
    right: 0,
    top: 0,
    width: 36,
  },
  name: {
    bottom: 8,
    position: "absolute",
    right: 10,
  },
  message: {
    boxSizing: "border-box",
    paddingRight: 36,
    wordBreak: "break-word",
  },
}));

const WRONG_PW_MSG = {
  error: true,
  helperText: "비밀번호가 맞지 않습니다",
};

const MessageCard = (props: Props) => {
  const { data, model } = props;
  const { message, name, id } = data;
  const [open, setOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [wrongPW, setWrongPW] = useState(false);
  const [pwInput, setPWInput] = useState("");

  const classes = useStyles();

  function openDialog() {
    setOpen(true);
    setWrongPW(false);
  }

  async function handleDelete() {
    const { password } = model.getData();

    if (password === sha256(pwInput)) {
      await model.delete();
      setIsDeleted(true);
      setOpen(false);
    } else {
      setWrongPW(true);
    }
  }

  function handleClose() {
    setPWInput("");
    setOpen(false);
  }

  function handlePWChange(ev) {
    if (ev && ev.target) {
      const newVal = ev.target.value;
      setPWInput(newVal);
    }
  }

  function handleKeyDown(ev) {
    if (ev.keyCode === 13) {
      handleDelete();
    }
  }

  return (
    <div className={cn(classes.MessageCard, { [classes.deleted]: isDeleted })}>
      <div className={classes.message}>{message}</div>
      <div className={classes.name}>- {name}</div>
      <Button className={classes.deleteButton} onClick={openDialog}>
        <Icon>delete_outline</Icon>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">메세지 삭제</DialogTitle>
        <DialogContent>
          <DialogContentText>
            이 메세지를 삭제하기 위해서는 비밀번호를 입력하세요
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="passwordInput"
            label="비밀번호"
            type="password"
            fullWidth
            onChange={handlePWChange}
            onKeyDown={handleKeyDown}
            value={pwInput}
            error={wrongPW}
            helperText={wrongPW ? WRONG_PW_MSG.helperText : undefined}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button onClick={handleDelete} color="primary">
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageCard;
