import React, { useState, useEffect } from "react";
import cn from "classnames";
import { makeStyles } from "@material-ui/styles";
import {
  Theme,
  TextField,
  Button,
  Typography,
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { fetchMessages, addMessage } from "utils/database";
import ParallaxComponent from "components/ParallaxComponent";
import Message from "models/Message";
import PageHeader from "components/PageHeader";
import ColorPalette from "styles/ColorPalette";
import MessageCardList from "components/MessageCardList";
import BackgroundTexture from "images/bg-texture.png";

import Writing from "images/writing.png";

type Props = {};

const useStyles = makeStyles((theme: Theme) => ({
  GuestBookPage: {
    backgroundColor: ColorPalette.defaultBackground,
    backgroundImage: `url(${BackgroundTexture})`,
    padding: "3rem 4%",
    position: "relative",
  },
  map: {
    "$GuestBookPage &": {
      border: 0,
      boxSizing: "border-box",
      padding: "0",
      width: "100%",
    },
  },
  guestForm: {
    fontSize: "1.5em",
    marginBottom: "2rem",
    textAlign: "center",
  },
  message: {
    minHeight: 150,
    width: "100%",
  },
  names: {},
  middle: {},
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "1rem",
    zIndex: 1,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  submitButton: {
    marginLeft: 8,
  },
  guestNumberSelect: {
    width: 100,
  },
  sendIcon: {
    transform: "rotate(-45deg) translateX(4px)",
  },
  writing: {
    position: "absolute",
    right: "50%",
    top: "50%",
    transform: "translate(270px, -260px)",
    zIndex: 0,
  },
  writingImg: {
    width: 200,
  },
  "@media (max-width: 450px)": {
    actions: {
      justifyContent: "space-between",
    },
  },
}));

interface GuestInputData {
  name?: string;
  guestNumber?: number;
  message?: string;
  password?: string;
}

const INPUT_TYPES = {
  TEXT: "TEXT",
  SELECT: "SELECT",
};

const InputTypeMap = {
  name: INPUT_TYPES.TEXT,
  guestNumber: INPUT_TYPES.SELECT,
  message: INPUT_TYPES.TEXT,
  password: INPUT_TYPES.TEXT,
};

const DEFAULT_INPUT_DATA = {
  name: "",
  guestNumber: null,
  message: "",
  password: "",
};

const GuestBookPage = (props: Props) => {
  const classes = useStyles();
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [onAsync, setOnAsync] = useState(false);
  const [inputData, setInputData] = useState<GuestInputData>({
    ...DEFAULT_INPUT_DATA,
  });

  async function fetchData() {
    try {
      setOnAsync(true);
      const messages = await fetchMessages();
      setOnAsync(false);
      setMessages(messages);
    } catch (err) {
      console.error(err);
      setOnAsync(false);
    }
  }

  async function onLoadAsync() {
    await fetchData();
  }

  useEffect(() => {
    onLoadAsync();
  }, []);

  async function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();
    try {
      setOnAsync(true);
      await addMessage(inputData);
      await fetchData();
      setOnAsync(false);
      setInputData({ ...DEFAULT_INPUT_DATA });
    } catch (error) {
      setOnAsync(false);
    }
  }

  function handleChange(kv: keyof GuestInputData) {
    return function(
      ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      const newVal = ev.target && ev.target.value;
      setInputData({ ...inputData, [kv]: newVal });
    };
  }

  return (
    <div className={cn(classes.GuestBookPage)}>
      <PageHeader label="GUEST BOOK" />
      <form
        className={classes.guestForm}
        autoComplete="off"
        onSubmit={handleSubmit}>
        <ParallaxComponent
          className={classes.writing}
          sensitivityY={3}
          offsetY={-140}>
          <img src={Writing} className={classes.writingImg} />
        </ParallaxComponent>
        <div className={cn(classes.names, classes.wrapper)}>
          <TextField
            id="name"
            label="성함"
            variant="outlined"
            required={true}
            color="primary"
            value={inputData.name}
            onChange={handleChange("name")}
            disabled={onAsync}
          />
          <FormControl className={classes.guestNumberSelect} variant="outlined">
            <InputLabel id="guest-number-select-label">인원 *</InputLabel>
            <Select
              labelId="guest-number-select-label"
              required={true}
              value={inputData.guestNumber}
              disabled={onAsync}
              onChange={handleChange("guestNumber")}>
              <MenuItem value={0}>불참</MenuItem>
              <MenuItem value={1}>1 명</MenuItem>
              <MenuItem value={2}>2 명</MenuItem>
              <MenuItem value={3}>3 명</MenuItem>
              <MenuItem value={4}>4 명</MenuItem>
              <MenuItem value={5}>5 명</MenuItem>
              <MenuItem value={6}>6 명</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={cn(classes.middle, classes.wrapper)}>
          <TextField
            className={classes.message}
            label="전하는 말"
            variant="outlined"
            color="primary"
            multiline
            onChange={handleChange("message")}
            disabled={onAsync}
            value={inputData.message}
          />
        </div>
        <div className={cn(classes.actions)}>
          <TextField
            id="password"
            label="비밀번호"
            type="password"
            autoComplete="current-password"
            color="primary"
            required={true}
            variant="outlined"
            onChange={handleChange("password")}
            disabled={onAsync}
            value={inputData.password}
          />
          <Button
            className={cn(classes.submitButton)}
            color="primary"
            variant="outlined"
            disabled={onAsync}
            type="submit">
            {onAsync ? (
              <CircularProgress size={24} />
            ) : (
              <>
                보내기
                <Icon className={cn("right-icon", classes.sendIcon)}>send</Icon>
              </>
            )}
          </Button>
        </div>
      </form>
      <MessageCardList messages={messages} />
    </div>
  );
};

export default GuestBookPage;
